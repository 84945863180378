import { template as template_e75f890b5c8242c2b647077b72c5df1b } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { modifier } from "ember-modifier";
import highlightSearch from "discourse/lib/highlight-search";
export default class HighlightSearch extends Component {
    highlight = modifier((element)=>{
        highlightSearch(element, this.args.highlight);
    });
    static{
        template_e75f890b5c8242c2b647077b72c5df1b(`
    <span {{this.highlight}}>{{yield}}</span>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
