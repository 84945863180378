import { template as template_3fc2584abf96464c9b741840e4769466 } from "@ember/template-compiler";
const FKControlMenuContainer = template_3fc2584abf96464c9b741840e4769466(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
