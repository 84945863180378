import { template as template_25e58ee4e04940029f53a19bceb90a1d } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_25e58ee4e04940029f53a19bceb90a1d(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
