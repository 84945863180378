import { template as template_5aa85c271dbd43e08901629b5185acb0 } from "@ember/template-compiler";
const FKLabel = template_5aa85c271dbd43e08901629b5185acb0(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
